export const patientRaceOptions = [
  { label: "Asian", value: "AS" },
  { label: "American Indian or Alaska Native", value: "AI" },
  { label: "Black or African American", value: "AA" },
  { label: "Native Hawaiian or Pacific Islander", value: "PI" },
  { label: "White Non-Hispanic", value: "WH" },
  { label: "Hispanic", value: "HI" },
  { label: "Other / Prefer not to answer", value: "OT" },
]

export const patientSexOptions = [
  { label: "Female", value: "F" },
  { label: "Male", value: "M" },
  { label: "Other / Prefer not to answer", value: "O" },
]

export const patientRaceToString: { [key: string]: string } =
  Object.fromEntries(
    patientRaceOptions.map(({ label, value }) => [value, label])
  )
