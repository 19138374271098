import React from "react"
import huxleySmall from "../HUXLEY_CENTERED_Small.png"
import { OrganizationOut } from "../../../generated/fetchclient"
import formatPhoneNumber from "../../../utils/phonenumber"

interface PdfTemplateProps {
  study_id: number | undefined
  sw_version: string | undefined
  organization: OrganizationOut | undefined
  children: React.ReactNode
  logo: string
}

const PdfTemplate: React.FC<PdfTemplateProps> = ({
  study_id,
  sw_version,
  organization = undefined,
  children,
  logo,
}) => {
  const org_data = organization
    ? {
        name: organization.name,
        address1: organization.locations[0].address1,
        address2: organization.locations[0].address2,
        state: organization.locations[0].state,
        city: organization.locations[0].city,
        zip: organization.locations[0].zip,
        phone: formatPhoneNumber(organization.contactPhone),
        logo: organization.logoUrl,
      }
    : {
        name: "Huxley Medical, Inc.",
        address1: "1465 Northside Dr NW, Suite 217 Atlanta, GA 30318",
        address2: "Suite 217 Atlanta, GA 30318",
        phone: "(888) 726-7239",
        logo: huxleySmall,
      }
  return (
    <div className="report-page">
      <div id="header">
        <div className="huxley-head-logo">
          <img alt="Huxley Head Logo" src={logo} />
        </div>
        <div className="title">
          <div className="section-header-title">SANSA Sleep Report</div>
          <p className="study-id">Study ID: {study_id}</p>
        </div>
        <div className="address">
          <div className="huxley-info">
            {org_data.name}
            <br />
            {org_data.address1}, {org_data.address2}
            <br />
            {org_data.city}, {org_data.state} {org_data.zip}
            <br />
            {org_data.phone}
          </div>
        </div>
      </div>
      {children}
      <div className="footer-items">
        <div className="footer-left">&copy; Huxley Medical, Inc.</div>
        <div className="footer-right">
          <div className="footer-right-text">
            <span>SW Ver. {sw_version}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PdfTemplate
